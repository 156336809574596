import './pwModal.css';
import React from 'react';
import { Dialog, Flex } from '@itwin/itwinui-react';
import { keyPressAsClick } from '../accessibility';
import { PWModalPrimaryButton, PWModalSecondaryButton, PWModalTertiaryButton } from './pwModalButton';
import { PWModalLoadingWrapper } from './pwModalLoadingWrapper';
/**
 * Wrapper around Dialog to display a consistent modal with optional loading page.
 * @param props PWModal props
 * @returns JSX.Element
 */
export function PWModal({ title, className, size = 'm', isLoading, loadingStyle, loadingText, children, primaryButton, secondaryButton, tertiaryButton, onClose, dialogProps }) {
    const sizeClass = size == 'l' ? 'pw-modal-lg' : 'pw-modal-md';
    const joinedClassName = [sizeClass, className].filter(Boolean).join(' ');
    function onKeyPress(event) {
        if ((primaryButton === null || primaryButton === void 0 ? void 0 : primaryButton.onClick) && !(primaryButton === null || primaryButton === void 0 ? void 0 : primaryButton.disabled)) {
            keyPressAsClick(primaryButton.onClick)(event);
        }
    }
    return (React.createElement(Dialog, { isOpen: true, onClose: onClose, isDraggable: true, isResizable: size == 'l', portal: true, preventDocumentScroll: true, trapFocus: true, onKeyUp: onKeyPress },
        React.createElement(Dialog.Backdrop, null),
        React.createElement(Dialog.Main, { className: joinedClassName, ...dialogProps },
            React.createElement(Dialog.TitleBar, { titleText: title, className: "pw-modal-title" }),
            React.createElement(Dialog.Content, null,
                React.createElement(PWModalLoadingWrapper, { isLoading: isLoading, loadingStyle: loadingStyle, loadingText: loadingText },
                    React.createElement(Flex, { flexDirection: "column", alignItems: "stretch", className: "pw-modal-flex" }, children))),
            React.createElement(Dialog.ButtonBar, null,
                tertiaryButton && React.createElement(PWModalTertiaryButton, { ...tertiaryButton }),
                primaryButton && React.createElement(PWModalPrimaryButton, { ...primaryButton }),
                secondaryButton && React.createElement(PWModalSecondaryButton, { ...secondaryButton })))));
}
