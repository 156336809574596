import { FeatureToggleClient } from '@bentley/feature-toggle-js';
import { getUserAttributes } from './samlParser';
/**
 * Initializes Bentley feature toggle client
 * @param getSamlToken Async function to get SAML token
 * @param clientSideId Feature toggle client key
 * @returns Bentley feature toggle client
 */
export async function buildFeatureToggleClient(getSamlToken, clientSideId) {
    const samlToken = await (getSamlToken === null || getSamlToken === void 0 ? void 0 : getSamlToken());
    if (!samlToken) {
        throw new Error('Error getting SAML token');
    }
    const featureToggleUser = buildFeatureToggleUser(samlToken);
    const client = new FeatureToggleClient();
    await client.initializeClient(clientSideId, featureToggleUser);
    return client;
}
function buildFeatureToggleUser(samlToken) {
    const { countryiso, name, userid, ultimatesite } = getUserAttributes(samlToken);
    if (!countryiso || !name || !userid) {
        throw new Error('Error getting user attributes');
    }
    const featureToggleUser = {
        id: userid,
        email: name,
        countryIso: countryiso,
        ultimateSite: parseInt(ultimatesite !== null && ultimatesite !== void 0 ? ultimatesite : '0')
    };
    return featureToggleUser;
}
