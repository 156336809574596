import { relatedPropertyName } from './relationshipClass';
/**
 * Builds a select expression for use in a query. @see {@link QueryOptions}
 * @param select Instructions for building a select expression
 * @param select.relationshipClass Relationship class details @see {@link RelationshipClass}
 * @param select.propertyName Property name to select on the class or related class *May not be used with properties*
 * @param select.properties `'all'` to select all properties or `'none'` to select instanceId only, *May not be used with propertyName*
 * @returns A select expression compatible for WSG queries
 */
export function buildSelect(select) {
    if (!select) {
        return '';
    }
    if (Array.isArray(select) && !select.length) {
        return '';
    }
    const selectExpression = buildSelectExpression(select);
    return `$select=${selectExpression}`;
}
function buildSelectExpression(select) {
    if (!Array.isArray(select)) {
        const { relationshipClass, propertyName, properties } = select;
        const propertyNameExpression = buildPropertyNameExpression(relationshipClass, propertyName, properties);
        return propertyNameExpression;
    }
    const selectExpression = select.map(buildSelectExpression).join(',');
    return selectExpression;
}
function buildPropertyNameExpression(relationshipClass, propertyName, properties) {
    if (properties == 'none' || propertyName == 'instanceId') {
        propertyName = '$id';
    }
    if (properties == 'all' || !propertyName) {
        propertyName = '*';
    }
    if (!relationshipClass) {
        return propertyName;
    }
    const propertyNameExpression = relatedPropertyName(relationshipClass, propertyName);
    return propertyNameExpression;
}
