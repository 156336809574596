import { itemIsFileType } from './itemAssertions';
/**
 * Retrieves file extension from a {@link PWItem}
 * @param item Item to parse; if a {@link PWProject} is passed, will return empty string
 * @returns File extension as lower case, or empty string if no extension is found
 */
export function itemFileExtension(item) {
    if (!itemIsFileType(item)) {
        return '';
    }
    return parseFileExtension(item.FileName);
}
/**
 * Retrieves file extension from a string
 * @param fileName File name to parse
 * @returns File extension as lower case, or empty string if no extension is found
 */
export function parseFileExtension(fileName) {
    if (!fileName) {
        return '';
    }
    const segments = fileName.split('.');
    if (segments.length <= 1) {
        return '';
    }
    return segments[segments.length - 1].toLowerCase();
}
/**
 * Retrieves file name without extension from a {@link PWItem}
 * @param item Item to parse; if a {@link PWProject} is passed, will return empty string
 * @returns File name without extension
 */
export function itemFileNameWithoutExtension(item) {
    if (!itemIsFileType(item)) {
        return '';
    }
    return parseFileNameWithoutExtension(item.FileName);
}
/**
 * Retrieves file name without extension from a string
 * @param fileName File name to parse
 * @returns File name without extension
 */
export function parseFileNameWithoutExtension(fileName) {
    const extension = parseFileExtension(fileName);
    const suffixLength = extension.length > 0 ? extension.length + 1 : 0;
    return fileName.substring(0, fileName.length - suffixLength);
}
