import { parseResponseRelationshipInstances, query } from '../http';
import { assertValidInstanceId } from '../validate';
/**
 * Get all older versions of a data item; must be most recent version
 * @param getOlderVersionsOptions Options for getting older versions
 * @param getOlderVersionsOptions.instanceId instanceId of the data item
 * @param getOlderVersionsOptions.httpService Configured http service for the datasource
 * @param getOlderVersionsOptions.requestOptions Options for the http request
 * @returns Older versions of the data item
 */
export async function getOlderVersions({ instanceId, httpService, requestOptions }) {
    assertValidInstanceId(instanceId);
    const url = query({
        schemaName: 'PW_WSG',
        className: 'Document',
        polymorphic: true,
        filter: { propertyName: 'instanceId', operator: 'eq', value: instanceId },
        select: buildVersionPropertySelect()
    });
    const response = await httpService.get(url, requestOptions);
    const versions = await parseResponseRelationshipInstances(response);
    return versions;
}
function buildVersionPropertySelect() {
    const select = [
        { properties: 'none' },
        {
            relationshipClass: {
                relationshipClassName: 'DocumentVersions',
                direction: 'forward',
                relatedClassName: 'Document',
                relatedClassPolymorphic: true
            },
            properties: 'all'
        }
    ];
    return select;
}
