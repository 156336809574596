/**
 * Merges headers into the request options
 * @param requestOptions Initial request options
 * @param headers Headers to merge into the request options
 * @returns Request options with headers merged in
 */
export function mergeHeadersIntoRequestOptions(requestOptions, headers) {
    const requestOptionsWithUploadHeaders = {
        ...requestOptions,
        headers: { ...requestOptions === null || requestOptions === void 0 ? void 0 : requestOptions.headers, ...headers }
    };
    return requestOptionsWithUploadHeaders;
}
