import { featureMap } from './features';
/**
 * Maps features from featureMap to Feature objects based on Bentley feature
 * toggle client
 * @param client Bentley feature toggle client
 * @returns Array of Feature objects
 */
export function featureMapValues(client) {
    const featureMapEntries = Object.entries(featureMap);
    const features = featureMapEntries.map(([featureFlag, flagName]) => buildFeature(client, featureFlag, flagName));
    return features;
}
function buildFeature(client, featureFlag, flagName) {
    try {
        const value = client.getVariation(flagName, false);
        const hasAccess = Boolean(value);
        return { featureFlag, hasAccess, value };
    }
    catch (err) {
        return { featureFlag, hasAccess: false, value: false };
    }
}
