/**
 * Builds an order by expression for use in a query. @see {@link QueryOptions}
 * @param orderBy Instructions for building an order by expression
 * @param orderBy.propertyName Property name to order by
 * @param orderBy.sortDirection Sort direction to use in the order by expression, defaults to 'asc'
 * @returns An order by expression compatible for WSG queries
 */
export function buildOrderBy(orderBy) {
    if (!orderBy) {
        return '';
    }
    if (Array.isArray(orderBy) && !orderBy.length) {
        return '';
    }
    const orderByExpression = buildOrderByExpression(orderBy);
    return `$orderby=${orderByExpression}`;
}
function buildOrderByExpression(orderBy) {
    if (!Array.isArray(orderBy)) {
        const propertyNameExpression = buildPropertyNameExpression(orderBy);
        return propertyNameExpression;
    }
    const orderByExpression = orderBy.map(buildOrderByExpression).join(',');
    return orderByExpression;
}
function buildPropertyNameExpression({ propertyName, sortDirection }) {
    if (sortDirection) {
        return `${propertyName} ${sortDirection}`;
    }
    return propertyName;
}
/**
 * Builds a skip expression for use in a query. @see {@link QueryOptions}
 * @param skip Number to skip
 * @returns A skip expression compatible for WSG queries
 */
export function buildSkip(skip) {
    if (!skip) {
        return '';
    }
    return `$skip=${skip}`;
}
/**
 * Builds a top expression for use in a query. @see {@link QueryOptions}
 * @param top Number of results to return
 * @returns A top expression compatible for WSG queries
 */
export function buildTop(top) {
    if (!top) {
        return '';
    }
    return `$top=${top}`;
}
