import { query } from '../http';
import { mergeHeadersIntoRequestOptions } from '../http/headers';
import { assertValidInstanceId } from '../validate';
/**
 * Creates a new document in the datasource; follow up with transferFile to upload the file
 * Use `createDocumentWithFile` to create and upload a file in one call
 * @param createDocumentWithFileOptions Options for uploading a file
 * @param createDocumentWithFileOptions.file File to upload
 * @param createDocumentWithFileOptions.parentId instanceId of the parent folder
 * @param createDocumentWithFileOptions.documentOptions Options for creating a document with custom properties
 * @param createDocumentWithFileOptions.httpService Configured http service for the datasource
 * @param createDocumentWithFileOptions.requestOptions Options for the http request
 * @returns The response from the document creation
 */
export async function createDocument({ file, parentId, documentOptions, httpService, requestOptions }) {
    assertValidInstanceId(parentId);
    const url = buildUploadUrl(documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.instanceId);
    const body = buildUploadBody(file, parentId, documentOptions);
    const headers = buildUploadHeaders(file, documentOptions);
    const uploadRequestOptions = mergeHeadersIntoRequestOptions(requestOptions, headers);
    const response = await httpService.post(url, body, uploadRequestOptions);
    return response;
}
function buildUploadUrl(instanceId) {
    const url = query({
        schemaName: 'PW_WSG',
        className: 'Document',
        instanceId
    });
    return url;
}
function buildUploadBody(file, parentId, documentOptions) {
    const documentInstance = buildDocumentInstance(parentId, file, documentOptions);
    const body = JSON.stringify({
        instance: documentInstance,
        file: file
    });
    return body;
}
function buildDocumentInstance(parentId, file, documentOptions) {
    var _a, _b;
    const changeState = (documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.instanceId) ? 'modified' : 'new';
    const properties = {
        Name: (_a = documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.Name) !== null && _a !== void 0 ? _a : file.name,
        Description: documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.Description,
        FileName: (_b = documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.FileName) !== null && _b !== void 0 ? _b : file.name,
        Version: documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.Version
    };
    const documentInstance = {
        schemaName: 'PW_WSG',
        className: 'Document',
        instanceId: documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.instanceId,
        changeState: changeState,
        properties: properties,
        relationshipInstances: [
            {
                schemaName: 'PW_WSG',
                className: 'DocumentParent',
                direction: 'forward',
                relatedInstance: {
                    schemaName: 'PW_WSG',
                    className: 'Project',
                    instanceId: parentId
                }
            }
        ]
    };
    return documentInstance;
}
/**
 * Builds the headers for uploading a file
 * @param file File to upload
 * @param documentOptions Options for creating a document with custom properties; specifically, may contain FileName property
 * @returns Headers for uploading a file
 */
export function buildUploadHeaders(file, documentOptions) {
    var _a;
    const fileName = (_a = documentOptions === null || documentOptions === void 0 ? void 0 : documentOptions.FileName) !== null && _a !== void 0 ? _a : file.name;
    const encodedFileName = encodeURIComponent(fileName);
    const contentDisposition = `attachment; IsFileNameUrlEncoded=true; filename="${encodedFileName}"`;
    const contentRange = `bytes */${file.size}`;
    const headers = {
        'Content-Disposition': contentDisposition,
        'Content-Range': contentRange
    };
    return headers;
}
