/**
 * Builds an api.ancestor expression for use in a query. @see {@link QueryOptions}
 * @param apiAncestor Information about the ancestor
 * @returns An api.ancestor expression compatible for WSG queries
 */
export function buildApiAncestor(apiAncestor) {
    if (!(apiAncestor === null || apiAncestor === void 0 ? void 0 : apiAncestor.className) || !(apiAncestor === null || apiAncestor === void 0 ? void 0 : apiAncestor.instanceId)) {
        return '';
    }
    const { schema, className, instanceId } = apiAncestor;
    const ancestorClass = schema ? `${schema}.${className}` : className;
    return `api.ancestor=${ancestorClass}-${instanceId}`;
}
/**
 * Builds an api.filtersettings expression for use in a query. @see {@link QueryOptions}
 * @param filterSettings Filter settings to apply
 * @returns An api.filtersettings expression compatible for WSG queries
 */
export function buildFilterSettings(filterSettings) {
    if (!filterSettings) {
        return '';
    }
    return `api.filtersettings=${filterSettings}`;
}
/**
 * Builds an api.selectsettings expression for use in a query. @see {@link QueryOptions}
 * @param selectSettings Select settings to apply
 * @returns An api.selectsettings expression compatible for WSG queries
 */
export function buildSelectSettings(selectSettings) {
    if (!selectSettings) {
        return '';
    }
    return `api.selectsettings=${selectSettings}`;
}
/**
 * Builds a custom query parameter expression for use in a query. @see {@link QueryOptions}
 * @param customQueryParameters Custom query parameters to apply
 * @returns A custom query parameter expression compatible for WSG queries
 */
export function buildCustomQueryParameters(customQueryParameters) {
    if (!customQueryParameters) {
        return '';
    }
    return Object.entries(customQueryParameters)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
}
