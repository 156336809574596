import { parseResponseInstances, query } from '../http';
import { byDefaultOrder } from '../properties';
import { assertValidInstanceId } from '../validate';
import { getDataItems } from './documents';
/**
 * Returns a Project
 * @param getProjectOptions Options for retrieving a Project
 * @param getProjectOptions.instanceId Project instanceId
 * @param getProjectOptions.selectProperties Properties to query; if not passed will query all properties
 * @param getProjectOptions.selectRelationships Optional related classes to query
 * @param getProjectOptions.httpService Configured http service for the datasource
 * @param getProjectOptions.requestOptions Options for the http request
 * @returns A Project; will throw if not found
 */
export async function getProject({ instanceId, selectProperties, selectRelationships, httpService, requestOptions }) {
    assertValidInstanceId(instanceId);
    const url = query({
        schemaName: 'PW_WSG',
        className: 'Project',
        instanceId: instanceId,
        select: buildProjectSelect(selectProperties, selectRelationships)
    });
    const response = await httpService.get(url, requestOptions);
    const items = await parseResponseInstances(response);
    return items[0];
}
/**
 * Returns children of a Project
 * @param getProjectChildrenOptions Options for retrieving items
 * @param getProjectChildrenOptions.parentId Project instanceId; pass null to retrieve projects at the datasource root
 * @param getProjectChildrenOptions.selectProperties Properties to query; if not passed will query all properties
 * @param getProjectChildrenOptions.selectRelationships Optional related classes to query
 * @param getProjectChildrenOptions.httpService Configured http service for the datasource
 * @param getProjectChildrenOptions.requestOptions Options for the http request
 * @returns Children of a Project
 */
export async function getProjectChildren({ parentId, selectProperties, selectRelationships, httpService, requestOptions }) {
    if (parentId === null) {
        const items = getDatasourceRootProjects({
            selectRelationships,
            httpService,
            requestOptions
        });
        return items;
    }
    assertValidInstanceId(parentId);
    const [projects, dataItems] = await Promise.all([
        getProjects({
            parentId,
            selectProperties,
            selectRelationships,
            httpService,
            requestOptions
        }),
        getDataItems({
            parentId,
            selectProperties,
            selectRelationships,
            httpService,
            requestOptions
        })
    ]);
    const items = [...projects, ...dataItems];
    return items;
}
/**
 * Returns Projects for a given parent
 * @param getProjectsOptions Options for retrieving projects
 * @param getProjectsOptions.parentId Parent instanceId; pass null to retrieve projects at the datasource root
 * @param getProjectsOptions.selectProperties Properties to query; if not passed will query all properties
 * @param getProjectsOptions.selectRelationships Optional related classes to query
 * @param getProjectsOptions.httpService Configured http service for the datasource
 * @param getProjectsOptions.requestOptions Options for the http request
 * @returns Projects for a given parent
 */
export async function getProjects({ parentId, selectProperties, selectRelationships, httpService, requestOptions }) {
    if (parentId === null) {
        return getDatasourceRootProjects({
            selectRelationships,
            httpService,
            requestOptions
        });
    }
    assertValidInstanceId(parentId);
    const filter = buildProjectFilter(parentId);
    const select = buildProjectSelect(selectProperties, selectRelationships);
    const url = query({
        schemaName: 'PW_WSG',
        className: 'Project',
        filter: filter,
        select: select
    });
    const response = await httpService.get(url, requestOptions);
    const items = await parseResponseInstances(response);
    items.sort(byDefaultOrder);
    return items;
}
/**
 * Returns root Projects for a datasource
 * @param getDatasourceRootOptions Options for retrieving root projects
 * @param getDatasourceRootOptions.selectProperties Properties to query; if not passed will query all properties
 * @param getDatasourceRootOptions.selectRelationships Optional related classes to query
 * @param getDatasourceRootOptions.httpService Configured http service for the datasource
 * @param getDatasourceRootOptions.requestOptions Options for the http request
 * @returns Root Projects for a datasource
 */
export async function getDatasourceRootProjects({ selectProperties, selectRelationships, httpService, requestOptions }) {
    const url = query({
        schemaName: 'PW_WSG',
        className: 'Project',
        filter: { propertyName: 'ParentGuid', operator: 'eq', value: null },
        select: buildProjectSelect(selectProperties, selectRelationships)
    });
    const response = await httpService.get(url, requestOptions);
    const items = await parseResponseInstances(response);
    items.sort(byDefaultOrder);
    return items;
}
function buildProjectFilter(parentId) {
    const filter = {
        relationshipClass: {
            relationshipClassName: 'ProjectParent',
            direction: 'forward',
            relatedClassName: 'Project'
        },
        propertyName: 'instanceId',
        operator: 'eq',
        value: parentId
    };
    return filter;
}
function buildProjectSelect(selectProperties, selectRelationships) {
    const select = selectProperties
        ? selectProperties.map((propertyName) => ({ propertyName }))
        : [{ properties: 'all' }];
    if (!selectRelationships) {
        return select;
    }
    if (selectRelationships.auditTrail) {
        select.push(projectAuditTrailSelect);
    }
    if (selectRelationships.parent) {
        select.push(projectParentSelect);
    }
    return select;
}
const projectAuditTrailSelect = {
    relationshipClass: {
        relationshipClassName: 'ProjectAuditTrail',
        direction: 'forward',
        relatedClassName: 'AuditTrail'
    },
    properties: 'all'
};
const projectParentSelect = {
    relationshipClass: {
        relationshipClassName: 'ProjectParent',
        direction: 'forward',
        relatedClassName: 'Project'
    },
    properties: 'all'
};
