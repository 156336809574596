import { query } from '../http';
import { subscribeToResponseProgress } from '../http/response/responseSubscription';
import { assertValidItem } from '../validate';
/**
 * Downloads a blob from the datasource
 * @param downloadFileOptions Options for downloading a blob
 * @param downloadFileOptions.document FileType to download (document or logical set)
 * @param downloadFileOptions.downloadOptions Options to include attribute exchange
 * @param downloadFileOptions.httpService Configured http service for the datasource
 * @param downloadFileOptions.requestOptions Options for the http request
 * @param downloadFileOptions.responseCallback Callback for tracking download progress
 * @returns A Blob containing the file data; will throw original HTTP response if not ok
 */
export async function downloadBlob({ document, downloadOptions, httpService, requestOptions, responseCallback }) {
    assertValidItem(document);
    const url = buildDownloadUrl(document, downloadOptions);
    const response = await httpService.get(url, requestOptions);
    if (responseCallback) {
        subscribeToResponseProgress(response, responseCallback);
    }
    if (!response.ok) {
        throw response;
    }
    const blob = await response.blob();
    return blob;
}
/**
 * Downloads a file from the datasource
 * @param downloadFileOptions Options for downloading a file
 * @param downloadFileOptions.document FileType to download (document or logical set)
 * @param downloadFileOptions.downloadOptions Options to include attribute exchange
 * @param downloadFileOptions.httpService Configured http service for the datasource
 * @param downloadFileOptions.requestOptions Options for the http request
 * @param downloadFileOptions.responseCallback Callback for tracking download progress
 * @returns A File built from response blob and document metadata; will throw original HTTP response if not ok
 */
export async function downloadFile({ document, downloadOptions, httpService, requestOptions, responseCallback }) {
    assertValidItem(document);
    const blob = await downloadBlob({
        document,
        downloadOptions,
        httpService,
        requestOptions,
        responseCallback
    });
    const file = buildFileFromBlob(blob, document);
    return file;
}
function buildDownloadUrl(document, downloadOptions) {
    const customQueryParameters = (downloadOptions === null || downloadOptions === void 0 ? void 0 : downloadOptions.attributeExchange)
        ? { doAttrExchange: true }
        : undefined;
    const url = query({
        schemaName: 'PW_WSG',
        className: document.className,
        instanceId: document.instanceId,
        file: true,
        customQueryParameters
    });
    return url;
}
function buildFileFromBlob(blob, document) {
    const fileName = document.FileName;
    const lastModified = new Date(document.FileUpdateTime).valueOf();
    const type = blob.type;
    const file = new File([blob], fileName, { lastModified, type });
    return file;
}
