/**
 * Allows the consumer of a Response to receive callbacks about the response status
 * @param response Response to subscribe to; will be cloned so as not to modify the original response
 * @param responseCallback Called with total bytes read and total content length as the response is processed
 */
export function subscribeToResponseProgress(response, responseCallback) {
    if (!response.status || !response.ok) {
        return;
    }
    const responseBody = response.clone().body;
    if (!responseBody) {
        return;
    }
    const contentLength = Number(response.headers.get('content-length'));
    const reader = responseBody.getReader();
    void readStream(reader, responseCallback, contentLength);
}
async function readStream(reader, responseCallback, contentLength, contentDownloaded = 0) {
    var _a;
    try {
        const { done, value } = await reader.read();
        contentDownloaded += (_a = value === null || value === void 0 ? void 0 : value.length) !== null && _a !== void 0 ? _a : 0;
        try {
            responseCallback(contentDownloaded, contentLength);
        }
        catch (e) {
            console.error(e);
        }
        if (done) {
            reader.releaseLock();
            return;
        }
    }
    catch {
        reader.releaseLock();
        return;
    }
    return readStream(reader, responseCallback, contentLength, contentDownloaded);
}
