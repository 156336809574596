import { parseResponseInstances, parseResponseRelationshipInstances, query } from '../http';
import { byDefaultOrder } from '../properties';
import { assertValidInstanceId } from '../validate';
import { documentAuditTrailSelect, documentCommandSelect, documentEnvironmentSelect, documentParentSelect } from './documents';
/**
 * Returns a FlatSet
 * @param getFlatSetOptions Options for retrieving a FlatSet
 * @param getFlatSetOptions.instanceId FlatSet instanceId
 * @param getFlatSetOptions.selectProperties Properties to query; if not passed will query all properties
 * @param getFlatSetOptions.selectRelationships Optional related classes to query
 * @param getFlatSetOptions.httpService Configured http service for the datasource
 * @param getFlatSetOptions.requestOptions Options for the http request
 * @returns A FlatSet; will throw if not found
 */
export async function getFlatSet({ instanceId, selectProperties, selectRelationships, httpService, requestOptions }) {
    assertValidInstanceId(instanceId);
    const url = query({
        schemaName: 'PW_WSG',
        className: 'FlatSet',
        instanceId: instanceId,
        select: buildFlatSetSelect(selectProperties, selectRelationships)
    });
    const response = await httpService.get(url, requestOptions);
    const items = await parseResponseInstances(response);
    return items[0];
}
/**
 * Returns children of a FlatSet
 * @param getFlatSetChildrenOptions Options for retrieving items
 * @param getFlatSetChildrenOptions.flatSetId FlatSet instanceId
 * @param getFlatSetChildrenOptions.selectProperties Properties to query; if not passed will query all properties
 * @param getFlatSetChildrenOptions.selectRelationships Optional related classes to query
 * @param getFlatSetChildrenOptions.httpService Configured http service for the datasource
 * @param getFlatSetChildrenOptions.requestOptions Options for the http request
 * @returns Children of a FlatSet
 */
export async function getFlatSetChildren({ flatSetId, selectProperties, selectRelationships, httpService, requestOptions }) {
    assertValidInstanceId(flatSetId);
    const flatSetSelect = buildFlatSetSelect(['instanceId'], {
        setRelationshipProperties: selectRelationships === null || selectRelationships === void 0 ? void 0 : selectRelationships.setRelationshipProperties
    });
    const flatSetChildrenSelect = buildFlatSetChildrenSelect(selectProperties, selectRelationships);
    const select = [...flatSetSelect, ...flatSetChildrenSelect];
    const url = query({
        schemaName: 'PW_WSG',
        className: 'FlatSet',
        instanceId: flatSetId,
        select: select
    });
    const response = await httpService.get(url, requestOptions);
    const items = await parseResponseRelationshipInstances(response);
    items.sort(byDefaultOrder);
    return items;
}
function buildFlatSetSelect(selectProperties, selectRelationships) {
    const select = selectProperties
        ? selectProperties.map((propertyName) => ({ propertyName }))
        : [{ properties: 'all' }];
    if (selectRelationships === null || selectRelationships === void 0 ? void 0 : selectRelationships.setRelationshipProperties) {
        select.push(setRelationshipPropertiesSelect);
    }
    if (selectRelationships === null || selectRelationships === void 0 ? void 0 : selectRelationships.auditTrail) {
        select.push(documentAuditTrailSelect);
    }
    if (selectRelationships === null || selectRelationships === void 0 ? void 0 : selectRelationships.environment) {
        select.push(documentEnvironmentSelect);
    }
    if (selectRelationships === null || selectRelationships === void 0 ? void 0 : selectRelationships.parent) {
        select.push(documentParentSelect);
    }
    if (selectRelationships === null || selectRelationships === void 0 ? void 0 : selectRelationships.wreCommands) {
        select.push(documentCommandSelect);
    }
    return select;
}
function buildFlatSetChildrenSelect(selectProperties, selectRelationships) {
    const select = [];
    if (selectProperties === null || selectProperties === void 0 ? void 0 : selectProperties.length) {
        const propertySelects = selectProperties.map((propertyName) => ({
            relationshipClass: {
                relationshipClassName: 'SetDocument',
                direction: 'forward',
                relatedClassName: 'Document',
                relatedClassPolymorphic: true
            },
            propertyName
        }));
        select.push(...propertySelects);
    }
    if (!(selectProperties === null || selectProperties === void 0 ? void 0 : selectProperties.length)) {
        select.push({
            relationshipClass: {
                relationshipClassName: 'SetDocument',
                direction: 'forward',
                relatedClassName: 'Document',
                relatedClassPolymorphic: true
            },
            properties: 'all'
        });
    }
    if (selectRelationships === null || selectRelationships === void 0 ? void 0 : selectRelationships.associatedSets) {
        select.push(associatedSetsSelect);
    }
    if (selectRelationships === null || selectRelationships === void 0 ? void 0 : selectRelationships.auditTrail) {
        select.push(setDocumentAuditTrailSelect);
    }
    if (selectRelationships === null || selectRelationships === void 0 ? void 0 : selectRelationships.environment) {
        select.push(setDocumentEnvironmentSelect);
    }
    if (selectRelationships === null || selectRelationships === void 0 ? void 0 : selectRelationships.parent) {
        select.push(setDocumentParentSelect);
    }
    if (selectRelationships === null || selectRelationships === void 0 ? void 0 : selectRelationships.wreCommands) {
        select.push(setDocumentCommandSelect);
    }
    return select;
}
const setRelationshipPropertiesSelect = {
    relationshipClass: {
        relationshipClassName: 'SetDocument'
    },
    properties: 'all'
};
const associatedSetsSelect = {
    relationshipClass: {
        relationshipClassName: 'SetDocument',
        direction: 'forward',
        relatedClassName: 'Document',
        relatedClassPolymorphic: true,
        nestedRelationship: {
            relationshipClassName: 'SetDocument',
            direction: 'backward',
            relatedClassName: 'FlatSet'
        }
    },
    properties: 'all'
};
const setDocumentAuditTrailSelect = {
    relationshipClass: {
        relationshipClassName: 'SetDocument',
        direction: 'forward',
        relatedClassName: 'Document',
        relatedClassPolymorphic: true,
        nestedRelationship: documentAuditTrailSelect.relationshipClass
    },
    properties: 'all'
};
const setDocumentCommandSelect = {
    relationshipClass: {
        relationshipClassName: 'SetDocument',
        direction: 'forward',
        relatedClassName: 'Document',
        relatedClassPolymorphic: true,
        nestedRelationship: documentCommandSelect.relationshipClass
    },
    properties: 'all'
};
const setDocumentEnvironmentSelect = {
    relationshipClass: {
        relationshipClassName: 'SetDocument',
        direction: 'forward',
        relatedClassName: 'Document',
        relatedClassPolymorphic: true,
        nestedRelationship: documentEnvironmentSelect.relationshipClass
    },
    properties: 'all'
};
const setDocumentParentSelect = {
    relationshipClass: {
        relationshipClassName: 'SetDocument',
        direction: 'forward',
        relatedClassName: 'Document',
        relatedClassPolymorphic: true,
        nestedRelationship: documentParentSelect.relationshipClass
    },
    properties: 'all'
};
