import { itemIsDataItem, itemIsProject } from './itemAssertions';
/**
 * Sorts items by work area, then by folder, then by name, then by version sequence
 * @example
 * const sortedItems = items.sort(byDefaultOrder);
 * @param a Item to compare
 * @param b Item to compare
 * @returns Sort function to sort PW items in default display order
 */
export function byDefaultOrder(a, b) {
    return byCascadingSort(a, b, byRichProject, byFolder, byName, byVersionSequence);
}
function byRichProject(a, b) {
    var _a, _b;
    if (!itemIsProject(a) || !itemIsProject(b)) {
        return 0;
    }
    return Number((_a = b.IsRichProject) !== null && _a !== void 0 ? _a : false) - Number((_b = a.IsRichProject) !== null && _b !== void 0 ? _b : false);
}
function byFolder(a, b) {
    return Number(itemIsProject(b)) - Number(itemIsProject(a));
}
function byName(a, b) {
    var _a, _b;
    return byAlphaNumericProperty((_a = a.Name) !== null && _a !== void 0 ? _a : '', (_b = b.Name) !== null && _b !== void 0 ? _b : '');
}
// Newest version at top, then by sequence
function byVersionSequence(a, b) {
    if (!itemIsDataItem(a) || !itemIsDataItem(b)) {
        return 0;
    }
    if (a.IsLatest) {
        return -1;
    }
    if (b.IsLatest) {
        return 1;
    }
    return a.Sequence - b.Sequence;
}
/**
 * Sorts by first sort provided; if same, proceeds to next sort until all sorts are exhausted
 * @param a Item to compare
 * @param b Item to compare
 * @param sortFunction Required sort function
 * @param rest Optional additional sort functions to apply in order
 * @returns Sort function to sort items by cascading sort functions
 */
function byCascadingSort(a, b, sortFunction, ...rest) {
    const primarySortValue = sortFunction(a, b);
    if (primarySortValue != 0 || !rest.length) {
        return primarySortValue;
    }
    return byCascadingSort(a, b, rest[0], ...rest.slice(1));
}
function byAlphaNumericProperty(a, b) {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'case' });
}
