export const featureMap = {
    copyFlatSetWithDCW: 'copy-flatset-with-dcw',
    disableEnvironmentLabels: 'disable-environment-labels',
    disableFormDateTimeLocalization: 'disable-form-datetime-conversion',
    document3DDesignReviewIntegration: 'documents-3d-design-review-integration',
    documentConnectionsInfoBanner: 'document-connections-info-banner',
    documentSettingsInfoBanner: 'document-settings-info-banner',
    documentViewExcludedExtensions: 'document-view-excluded-extensions',
    driveSyncMultiMode: 'show-drive-multi-connection-mode',
    enableOidcForWsg: 'enable-oidc-for-wsg',
    limitBasicSearchProperties: 'limit-basic-search-properties',
    office365ProjectSettings: 'office365-project-settings',
    pwDriveLegacyConnection: 'pw-drive-legacy-connection',
    pwDriveStatusUpdateFrequency: 'pw-drive-status-update-frequency',
    pwPluginVersionData: 'ec-plugin-version-data',
    shareConnectionV1: 'share-connection-phase-1',
    showCoauthoringUpdates: 'show-co-authoring-updates',
    showCopyAction: 'show-copy-action',
    showCustomHtmlLinks: 'show-custom-html-links',
    showDCWForCopyWorkflows: 'show-dcw-for-copy-workflows',
    showDocCodeEditing: 'show-doc-code-editing',
    showDriveSettings: 'show-drive-settings',
    showO365SignOut: 'show-o-365-signout-button',
    showPdfMarkupDocumentPreview: 'show-pdf-markup-document-preview',
    showPdfRenditionAction: 'show-pdf-rendition-action',
    showPicklistDropdowns: 'show-picklist-dropdowns',
    showPrimaryConnectionInfoBanner: 'show-primary-connection-info-banner',
    showPWDMIntegration: 'show-pwdm-integration',
    showPWWebViewSavedSearchLink: 'show-pw-web-view-saved-search-link',
    showRecycleBin: 'show-recycle-bin',
    showShortenedWebLinks: 'show-shortened-web-links',
    showUserSyncImprovements: 'show-user-sync-improvements',
    showViewManagerNewViewButton: 'show-view-manager-new-view-button',
    showWebViewDocumentViewer: 'show-web-view-document-viewer',
    updateWsgVersionString: 'update-wsg-version-string',
    useAttributeExchange: 'use-attribute-exchange',
    useKeyboardShortcuts: 'use-keyboard-shortcuts',
    userSync: 'user-sync',
    userSyncV2: 'user-sync-v2'
};
