import { buildApiAncestor, buildCustomQueryParameters, buildFilterSettings, buildSelectSettings } from './customQueryParameters';
import { buildFilter } from './filterBuilder';
import { buildOrderBy, buildSkip, buildTop } from './queryParameters';
import { buildSelect } from './selectBuilder';
/**
 * Builds a query string for use with the WSG API. The {@link QueryOptions} type restricts some invalid combinations.
 * @param options Options to control the query
 * @param options.schemaName The name of the WSG schema to query, e.g., PW_WSG
 * @param options.className The name of the WSG class to query, e.g., Document; if more than one passed, the query will include instances of all classes
 * @param options.polymorphic If true, the query will include subclasses of the class specified by className
 * @param options.instanceId instanceId to query *May not be used with count, filter, orderBy, skip, top, filterSettings, or selectSettings*
 * @param options.query If true, pass a queryExpression in the body of a POST request *May not be used with instanceId*
 * @param options.count If true, the query will return the number of records that match the query *May not be used with instanceId*
 * @param options.file Use to download a file *Must include instanceId*
 * @param options.filter One or more {@link Filter} configurations, which are all joined with AND
 * @param options.select One or more {@link Select} configurations
 * @param options.orderBy One or more {@link OrderBy} configurations
 * @param options.skip The number of records to skip
 * @param options.top The maximum number of records to return
 * @param options.apiAncestor Queries instances directly or indirectly related to specified ancestor
 * @param options.filterSettings May be 'CaseInsensitive' *Requires filter to be passed*
 * @param options.selectSettings Additional options for WSG to use while selecting instances *Requires select to be passed*
 * @returns A valid query expression to run a query against any WSG datasource
 */
export function query(options) {
    const route = buildRoute(options);
    const odataQuery = buildQueryExpression(options);
    const query = `${route}${odataQuery}`;
    return query;
}
/**
 * Builds a route for use with the WSG API. The {@link RouteOptions} type restricts some invalid combinations.
 * @param routeOptions Options to control the route
 * @param routeOptions.schemaName The name of the WSG schema to query, e.g., PW_WSG
 * @param routeOptions.className The name of the WSG class to query, e.g., Document; if more than one passed, the query will include instances of all classes
 * @param routeOptions.polymorphic If true, the query will include subclasses of the class specified by className
 * @param routeOptions.instanceId instanceId to query *May not be used with count*
 * @param routeOptions.query If true, pass a queryExpression in the body of a POST request *May not be used with instanceId*
 * @param routeOptions.count If true, the query will return the number of records that match the query *May not be used with instanceId*
 * @param routeOptions.file Use to download a file *Must include instanceId*
 * @returns A valid route expression to run a query against any WSG datasource
 */
export function buildRoute({ schemaName, className, polymorphic, instanceId, query, count, file }) {
    const schemaSegment = schemaName;
    const classSegment = buildClassSegment(className, polymorphic);
    const instanceSegment = instanceId ? `/${instanceId}` : '';
    const querySegment = query ? '/$query' : '';
    const countSegment = count ? '/$count' : '';
    const fileSegment = file ? '/$file' : '';
    const route = `${schemaSegment}${classSegment}${instanceSegment}${querySegment}${countSegment}${fileSegment}`;
    return route;
}
/**
 * Builds a query expression for use with the WSG API. May be used in url or in body of POST. The {@link QueryBuilderOptions} type restricts some invalid combinations.
 * @param options Options to control the query
 * @param options.filter One or more {@link Filter} configurations, which are all joined with AND
 * @param options.select One or more {@link Select} configurations
 * @param options.orderBy One or more {@link OrderBy} configurations
 * @param options.skip The number of records to skip
 * @param options.top The maximum number of records to return
 * @param options.apiAncestor Queries instances directly or indirectly related to specified ancestor
 * @param options.filterSettings May be 'CaseInsensitive' *Requires filter to be passed*
 * @param options.selectSettings Additional options for WSG to use while selecting instances *Requires select to be passed*
 * @param options.customQueryParameters Additional query parameters to pass to the WSG API
 * @returns A valid query expression to run a query against any WSG datasource
 */
export function buildQueryExpression(options) {
    const filter = buildFilter(options.filter);
    const select = buildSelect(options.select);
    const orderBy = buildOrderBy(options.orderBy);
    const skip = buildSkip(options.skip);
    const top = buildTop(options.top);
    const apiAncestor = buildApiAncestor(options.apiAncestor);
    const apiFilterSettings = buildFilterSettings(options.filterSettings);
    const apiSelectSettings = buildSelectSettings(options.selectSettings);
    const customQueryParameters = buildCustomQueryParameters(options.customQueryParameters);
    const odataQuery = joinQueryExpressions([
        filter,
        select,
        orderBy,
        skip,
        top,
        apiAncestor,
        apiFilterSettings,
        apiSelectSettings,
        customQueryParameters
    ]);
    return odataQuery;
}
function buildClassSegment(className, polymorphic) {
    const classNames = Array.isArray(className) ? className : [className];
    const classSegment = classNames
        .map((className) => (polymorphic ? `${className}!poly` : className))
        .join(',');
    return `/${classSegment}`;
}
function joinQueryExpressions(queryExpressions) {
    const validQueryExpressions = queryExpressions.filter((expression) => expression.length > 0);
    if (!validQueryExpressions.length) {
        return '';
    }
    const query = validQueryExpressions.join('&');
    return `?${query}`;
}
