/**
 * Default cache service for caching requests with {@link HttpService}
 */
export class CacheService {
    constructor({ name }) {
        this.cacheSupportedEnvironment = 'caches' in window;
        this.name = name;
    }
    /**
     * Deletes cache storage for the specified cache
     * @returns True if cache deleted
     */
    async clear() {
        if (!this.cacheSupportedEnvironment) {
            return true;
        }
        const cacheCleared = await caches.delete(this.name);
        return cacheCleared;
    }
    /**
     * Clears any cached entries that partially match the passed string(s)
     * @param matches Single string or array of strings to match against
     */
    async clearEntriesMatching(matches) {
        if (!this.cacheSupportedEnvironment) {
            return;
        }
        if (Array.isArray(matches)) {
            for (const match of matches) {
                await this.clearEntriesMatching(match);
            }
            return;
        }
        const cache = await this.getCache();
        const keys = await cache.keys();
        for (const key of keys) {
            if (key.url.includes(matches)) {
                await cache.delete(key);
            }
        }
    }
    /**
     * Attempts to return a cached response and update the cache in the background.
     * If no cached response is found, the response is fetched and cached.
     * If a cached response is found, the response is returned and the cache is updated in the background.
     * If the response is not ok or 304, the response is returned and the cache is not updated.
     * If cache is not supported or a non-GET/HEAD request is made, the response is fetched and returned.
     * @param url Url to fetch
     * @param requestInit RequestInit object
     * @param cacheSuffix Optional suffix to append to the cache name
     * @returns Response
     */
    async fetch(url, requestInit, cacheSuffix) {
        if (!this.allowCacheFetch(requestInit)) {
            return fetch(url, requestInit);
        }
        const cachedResponse = await this.cachedResponse(url, cacheSuffix);
        if (!cachedResponse) {
            const response = await this.fetchAndCache(url, requestInit, cacheSuffix);
            return response.clone();
        }
        const etag = cachedResponse.headers.get('etag');
        void this.backgroundCacheUpdate(url, requestInit, etag, cacheSuffix);
        return cachedResponse.clone();
    }
    getCache(cacheSuffix) {
        const cacheName = `${this.name}${cacheSuffix ? ' - ' + cacheSuffix : ''}`;
        return caches.open(cacheName);
    }
    async cachedResponse(url, cacheSuffix) {
        const cache = await this.getCache(cacheSuffix);
        const cachedResponse = await cache.match(url);
        return cachedResponse;
    }
    async fetchAndCache(url, requestInit, cacheSuffix) {
        try {
            const response = await fetch(url, requestInit);
            if (response.ok && response.status != 304) {
                await this.cacheResponse(response, cacheSuffix);
            }
            return response;
        }
        catch (e) {
            if (e.name == 'AbortError') {
                return new Response(null, { status: 499 });
            }
            throw e;
        }
    }
    async cacheResponse(response, cacheSuffix) {
        const cache = await this.getCache(cacheSuffix);
        await cache.put(response.url, response.clone());
    }
    backgroundCacheUpdate(url, requestInit, etag, cacheSuffix) {
        if (etag) {
            requestInit.headers = { ...requestInit.headers, 'If-None-Match': etag };
        }
        try {
            // Do not await this; this is a background task
            setTimeout(() => {
                void this.fetchAndCache(url, requestInit, cacheSuffix);
            });
        }
        catch {
            // Catch error to suppress 'User aborted a request' message
        }
    }
    allowCacheFetch(requestInit) {
        if (requestInit.method != 'GET' && requestInit.method != 'HEAD') {
            return false;
        }
        if (!this.cacheSupportedEnvironment) {
            return false;
        }
        return true;
    }
}
