import { authorizationFactory } from './authorizationFactory';
/**
 * Default authorization service for authorizing requests with {@link HttpService}
 * Initialize with either `userCredentials` for basic authorization, `getOidcToken` function, or `getSamlToken` function
 * Must include exactly one of the three options
 */
export class AuthorizationService {
    constructor(options) {
        if (!options.userCredentials &&
            !options.getOidcToken &&
            !options.getSamlToken) {
            throw new Error('AuthorizationService requires either userCredentials, getOidcToken, or getSamlToken to be defined');
        }
        this.userCredentials = AuthorizationService.encodeCredentials(options.userCredentials);
        this.getOidcToken = options.getOidcToken;
        this.getSamlToken = options.getSamlToken;
    }
    get authorizationType() {
        if (this.getOidcToken) {
            return 'OIDC token';
        }
        if (this.getSamlToken) {
            return 'SAML token';
        }
        return 'user credentials';
    }
    async header() {
        if (this.userCredentials) {
            return this.basicAuthorizationHeader(this.userCredentials);
        }
        if (this.getOidcToken) {
            return this.getTokenHeader(this.getOidcToken, 'Bearer');
        }
        if (this.getSamlToken) {
            return this.getTokenHeader(this.getSamlToken, 'SAML');
        }
        return undefined;
    }
    static encodeCredentials(credentials) {
        if (!credentials) {
            return undefined;
        }
        if ('userName' in credentials) {
            const joinedNameAndPassword = `${credentials.userName}:${credentials.password}`;
            return window.btoa(unescape(encodeURIComponent(joinedNameAndPassword)));
        }
        return credentials.encodedCredentials;
    }
    basicAuthorizationHeader(encodedCredentials) {
        return `Basic ${encodedCredentials}`;
    }
    async getTokenHeader(getToken, scheme) {
        const token = await getToken();
        if (!this.validateToken(token)) {
            return undefined;
        }
        return `${scheme} ${token}`;
    }
    validateToken(token) {
        if (!token) {
            console.error(`Unable to retrieve token`);
            return false;
        }
        return true;
    }
}
AuthorizationService.Create = authorizationFactory;
