import { query } from '../http';
import { assertValidInstanceId } from '../validate';
/**
 * Creates a new folder in the datasource
 * @param createFolderOptions Options for creating a folder
 * @param createFolderOptions.parentId instanceId of the parent folder
 * @param createFolderOptions.projectOptions Properties to apply to the new folder
 * @param createFolderOptions.httpService Configured http service for the datasource
 * @param createFolderOptions.requestOptions Options for the http request
 * @returns The response from the folder creation
 */
export async function createFolder({ parentId, projectOptions, httpService, requestOptions }) {
    assertValidInstanceId(parentId);
    const url = buildCreateFolderUrl();
    const body = buildCreateFolderBody(parentId, projectOptions);
    const response = await httpService.post(url, body, requestOptions);
    return response;
}
function buildCreateFolderUrl() {
    const url = query({ schemaName: 'PW_WSG', className: 'Project' });
    return url;
}
function buildCreateFolderBody(parentId, projectOptions) {
    const folderInstance = buildFolderInstance(parentId, projectOptions);
    const body = JSON.stringify({
        instance: folderInstance
    });
    return body;
}
function buildFolderInstance(parentId, projectOptions) {
    const folderInstance = {
        schemaName: 'PW_WSG',
        className: 'Project',
        changeState: 'new',
        properties: projectOptions,
        relationshipInstances: [
            {
                schemaName: 'PW_WSG',
                className: 'ProjectParent',
                direction: 'forward',
                relatedInstance: {
                    schemaName: 'PW_WSG',
                    className: 'Project',
                    instanceId: parentId
                }
            }
        ]
    };
    return folderInstance;
}
