import { relatedPropertyName } from './relationshipClass';
/**
 * Builds a filter expression for use in a query. @see {@link QueryOptions}
 * @param filter Instructions for building a filter expression
 * @param filter.relationshipClass Relationship class details @see {@link RelationshipClass}
 * @param filter.propertyName Property name to query on the class or related class
 * @param filter.operator Operator to use in the filter expression *Certain operators require specific value types*
 * @param filter.value Value to use in the filter expression *Certain value types may only be used with specific operators*
 * @returns A filter expression compatible for WSG queries
 */
export function buildFilter(filter) {
    if (!filter) {
        return '';
    }
    if (Array.isArray(filter) && !filter.length) {
        return '';
    }
    const filterExpression = buildFilterExpression(filter);
    return `$filter=${filterExpression}`;
}
function buildFilterExpression(filter) {
    if (!Array.isArray(filter)) {
        const { relationshipClass, propertyName, operator, value } = filter;
        const propertyNameExpression = buildPropertyNameExpression(relationshipClass, propertyName);
        const valueExpression = buildValueExpression(value);
        if (isCanonicalFunction(operator)) {
            return canonicalFunctionExpression(propertyNameExpression, operator, valueExpression);
        }
        return relationalExpression(propertyNameExpression, operator, valueExpression);
    }
    const filterExpression = filter.map(buildFilterExpression).join('+and+');
    return `(${filterExpression})`;
}
function buildPropertyNameExpression(relationshipClass, propertyName) {
    if (propertyName == 'instanceId') {
        propertyName = '$id';
    }
    if (!relationshipClass) {
        return propertyName;
    }
    const propertyNameExpression = relatedPropertyName(relationshipClass, propertyName);
    return propertyNameExpression;
}
function buildValueExpression(value) {
    if (Array.isArray(value)) {
        return `['${value.join("','")}']`;
    }
    if (typeof value === 'number' ||
        typeof value === 'boolean' ||
        value === null) {
        return String(value);
    }
    if (value instanceof Date) {
        return `datetime'${value.toISOString()}'`;
    }
    return `'${value}'`;
}
function isCanonicalFunction(operator) {
    return (operator == 'startswith' ||
        operator == 'endswith' ||
        operator == 'contains' ||
        operator == 'matchesregex' ||
        operator == 'intersects');
}
function canonicalFunctionExpression(propertyNameExpression, operator, valueExpression) {
    return `${operator}(${propertyNameExpression},${valueExpression})`;
}
function relationalExpression(propertyNameExpression, operator, valueExpression) {
    return `${propertyNameExpression}+${operator}+${valueExpression}`;
}
