/**
 * Ensures that an item has a className and instanceId
 * @param item Item to validate
 * @throws Error if item is missing className or instanceId
 */
export function assertValidItem(item) {
    assertValidClassName(item.className);
    assertValidInstanceId(item.instanceId);
}
function assertValidClassName(className) {
    if (!className || !className.length) {
        throw new Error('Invalid className');
    }
    if (!['Project', 'Document', 'FlatSet', 'LogicalSet'].includes(className)) {
        throw new Error('Invalid className');
    }
}
/**
 * Ensures that an instanceId exists
 * @param instanceId InstanceId to validate
 * @throws Error if instanceId is missing
 */
export function assertValidInstanceId(instanceId) {
    if (!instanceId || !instanceId.length) {
        throw new Error('Invalid instanceId');
    }
}
