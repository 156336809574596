/**
 * Builds a full format relationship class expression for use in a select or filter
 * @param relationshipClass Relationship class details
 * @param relationshipClass.relationshipSchema Schema name of the relationship class e.g., PW_WSG
 * @param relationshipClass.relationshipClassName Name of the relationship class e.g., DocumentParent *If relatedClassName is not provided, will query properties on this class*
 * @param relationshipClass.nestedRelationship Nested relationship class details to query related classes
 * @param relationshipClass.direction Direction of the relationship class *Requires relatedClassName*
 * @param relationshipClass.relatedSchema Schema name of the related class e.g., PW_WSG *Requires relatedClassName*
 * @param relationshipClass.relatedClassName Name of the related class e.g., Project
 * @param relationshipClass.relatedClassPolymorphic If true, the query will include subclasses of the class specified by relatedClassName *Requires relatedClassName*
 * @param relatedProperty Property name of the related class
 * @returns Full format relationship class expression
 */
export function relatedPropertyName(relationshipClass, relatedProperty) {
    const { relationshipSchema, relationshipClassName, direction } = relationshipClass;
    const originRelationshipClass = relationshipSchema
        ? `${relationshipSchema}.${relationshipClassName}`
        : relationshipClassName;
    const targetProperty = buildTargetClass(relationshipClass);
    const relatedClassExpression = buildRelatedPropertyExpression(originRelationshipClass, direction, targetProperty);
    if (relationshipClass.nestedRelationship) {
        const nestedRelatedProperty = relatedPropertyName(relationshipClass.nestedRelationship, relatedProperty);
        return `${relatedClassExpression}/${nestedRelatedProperty}`;
    }
    return `${relatedClassExpression}.${relatedProperty}`;
}
function buildTargetClass({ relatedSchema, relatedClassName, relatedClassPolymorphic }) {
    if (!relatedClassName) {
        return '';
    }
    let targetClass = relatedSchema
        ? `${relatedSchema}.${relatedClassName}`
        : relatedClassName;
    if (relatedClassPolymorphic) {
        targetClass += '!poly';
    }
    return targetClass;
}
function buildRelatedPropertyExpression(originRelationshipClass, direction, targetProperty) {
    if (!direction) {
        return `${originRelationshipClass}${targetProperty}`;
    }
    const relatedPropertyExpression = `${originRelationshipClass}-${direction}-${targetProperty}`;
    return relatedPropertyExpression;
}
