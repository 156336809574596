import { parseResponseInstances, query } from '../http';
import { byDefaultOrder } from '../properties';
import { assertValidInstanceId } from '../validate';
import { documentAuditTrailSelect, documentCommandSelect, documentEnvironmentSelect, documentParentSelect } from './documents';
/**
 * Returns documents related to a LogicalSet
 * @param getLogicalSetChildren Options for retrieving documents related to a LogicalSet
 * @param getLogicalSetChildren.logicalSetId LogicalSet instanceId
 * @param getLogicalSetChildren.selectProperties Properties to query; if not passed will query all properties
 * @param getLogicalSetChildren.selectRelationships Optional related classes to query
 * @param getLogicalSetChildren.httpService Configured http service for the datasource
 * @param getLogicalSetChildren.requestOptions Options for the http request
 * @returns Documents related to a LogicalSet
 */
export async function getLogicalSetChildren({ logicalSetId, selectProperties, selectRelationships, httpService, requestOptions }) {
    assertValidInstanceId(logicalSetId);
    const filter = buildLogicalSetFilter(logicalSetId);
    const select = buildLogicalSetSelect(selectProperties, selectRelationships);
    const url = query({
        schemaName: 'PW_WSG',
        className: 'Document',
        polymorphic: true,
        filter,
        select
    });
    const response = await httpService.get(url, requestOptions);
    const items = await parseResponseInstances(response);
    items.sort(byDefaultOrder);
    return items;
}
function buildLogicalSetFilter(logicalSetId) {
    const filter = {
        relationshipClass: {
            relationshipClassName: 'SetDocument',
            direction: 'backward',
            relatedClassName: 'LogicalSet'
        },
        propertyName: 'instanceId',
        operator: 'eq',
        value: logicalSetId
    };
    return filter;
}
function buildLogicalSetSelect(selectProperties, selectRelationships) {
    const select = selectProperties
        ? selectProperties.map((propertyName) => ({ propertyName }))
        : [{ properties: 'all' }];
    if (!selectRelationships) {
        return select;
    }
    if (selectRelationships.auditTrail) {
        select.push(documentAuditTrailSelect);
    }
    if (selectRelationships.environment) {
        select.push(documentEnvironmentSelect);
    }
    if (selectRelationships.parent) {
        select.push(documentParentSelect);
    }
    if (selectRelationships.wreCommands) {
        select.push(documentCommandSelect);
    }
    return select;
}
