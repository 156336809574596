import { parseResponseInstances, query } from '../http';
import { buildQueryExpression, buildRoute } from '../http/query/queryBuilder';
import { assertValidInstanceId } from '../validate';
/**
 * Returns an Item by instanceId; may be any type of PWItem
 * @param getItemOptions Options for retrieving an Item
 * @param getItemOptions.instanceId Item instanceId
 * @param getItemOptions.selectProperties Properties to query; if not passed will query all properties
 * @param getItemOptions.httpService Configured http service for the datasource
 * @param getItemOptions.requestOptions Options for the http request
 * @returns An Item; will throw if not found
 */
export async function getItem({ instanceId, selectProperties, httpService, requestOptions }) {
    assertValidInstanceId(instanceId);
    const select = buildItemSelect(selectProperties);
    const url = query({
        schemaName: 'PW_WSG',
        className: ['Project', 'Document'],
        polymorphic: true,
        filter: { propertyName: 'instanceId', operator: 'eq', value: instanceId },
        select: select
    });
    const response = await httpService.get(url, requestOptions);
    const items = await parseResponseInstances(response);
    return items[0];
}
/**
 * Returns Items by instanceIds; may be any type of PWItem
 * @param getItemsOptions Options for retrieving Items
 * @param getItemsOptions.instanceIds Item instanceIds
 * @param getItemsOptions.selectProperties Properties to query; if not passed will query all properties
 * @param getItemsOptions.httpService Configured http service for the datasource
 * @param getItemsOptions.requestOptions Options for the http request
 * @returns Items
 */
export async function getItems({ instanceIds, selectProperties, httpService, requestOptions }) {
    instanceIds.forEach(assertValidInstanceId);
    const select = buildItemSelect(selectProperties);
    const filter = buildItemFilter(instanceIds);
    const url = buildRoute({
        schemaName: 'PW_WSG',
        className: ['Project', 'Document'],
        polymorphic: true,
        query: true
    });
    const query = buildQueryExpression({ filter, select });
    const response = await httpService.post(url, query, requestOptions);
    const items = await parseResponseInstances(response);
    return items;
}
function buildItemFilter(instanceIds) {
    const filter = {
        propertyName: 'instanceId',
        operator: 'in',
        value: instanceIds
    };
    return filter;
}
function buildItemSelect(selectProperties) {
    if (!selectProperties) {
        return [{ properties: 'all' }];
    }
    return selectProperties.map((propertyName) => ({ propertyName }));
}
