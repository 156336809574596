import { createInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
const i18nInstance = createInstance({
    detection: { order: ['navigator'] },
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: { escapeValue: false },
    nonExplicitSupportedLngs: true,
    react: { useSuspense: true }
})
    .use(LanguageDetector)
    .use(initReactI18next);
/**
 * Create a type-safe translation function. This function will only accept keys that are present in the resource file.
 * @param resources Resource file in format { en: { translation: { key: value } } }
 * @returns Type-safe translation function
 */
export function typeSafeTranslate(resources) {
    if (!i18nInstance.isInitialized) {
        void i18nInstance.init({ resources });
    }
    else {
        for (const language in resources) {
            const resource = resources[language].translation;
            i18nInstance.addResourceBundle(language, 'translation', resource, true);
        }
    }
    function typeSafeTranslate(key, options) {
        return i18nInstance.t(key, options);
    }
    return typeSafeTranslate;
}
