import { xml2js } from 'xml-js';
/**
 * Parses user attributes from SAML token
 * @param token SAML token
 * @returns User attributes found in SAML token
 */
export function getUserAttributes(token) {
    try {
        const decodedToken = window.atob(token);
        const samlObj = xml2js(decodedToken, { compact: true });
        const userAttributes = samlAttributeArrayToObject(getAttributesFromSamlObject(samlObj));
        return userAttributes;
    }
    catch {
        return getEmptyUserAttributes();
    }
}
function getAttributesFromSamlObject(samlObj) {
    const assertion = samlObj['saml:Assertion'];
    const attributeStatement = assertion['saml:AttributeStatement'];
    const attributes = attributeStatement['saml:Attribute'];
    return attributes;
}
function samlAttributeArrayToObject(attributes) {
    return attributes.reduce((prev, curr) => {
        var _a, _b;
        const objKey = (_b = (_a = curr._attributes) === null || _a === void 0 ? void 0 : _a.AttributeName) !== null && _b !== void 0 ? _b : '';
        const attributeValue = curr['saml:AttributeValue'];
        const objVal = parseAttributeValue(attributeValue);
        return { ...prev, [objKey]: objVal };
    }, {});
}
function parseAttributeValue(attributeValue) {
    var _a;
    if (Array.isArray(attributeValue)) {
        return attributeValue.map(parseAttributeValue);
    }
    else {
        return (_a = attributeValue._text) !== null && _a !== void 0 ? _a : '';
    }
}
function getEmptyUserAttributes() {
    return {
        userid: '',
        name: '',
        countryiso: '',
        ultimatesite: ''
    };
}
