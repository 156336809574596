import { query } from '../http';
import { assertValidItem } from '../validate';
/**
 * Updates an item's properties; not all properties are updatable
 * @param modifyItemOptions Options for updating an item's properties
 * @param modifyItemOptions.item Item to update
 * @param modifyItemOptions.modifyOptions Options to include attribute exchange
 * @param modifyItemOptions.properties Object containing properties to update
 * @param modifyItemOptions.httpService Configured http service for the datasource
 * @param modifyItemOptions.requestOptions Options for the http request
 * @returns The response from the update
 */
export async function modifyItem({ item, modifyOptions, properties, httpService, requestOptions }) {
    assertValidItem(item);
    const url = query({
        schemaName: 'PW_WSG',
        className: item.className,
        instanceId: item.instanceId
    });
    const body = buildModifyBody(item, properties, modifyOptions);
    const response = await httpService.post(url, body, requestOptions);
    return response;
}
function buildModifyBody(item, properties, modifyOptions) {
    const requestOptions = buildCustomOptions(modifyOptions);
    return JSON.stringify({
        instance: {
            schemaName: 'PW_WSG',
            className: item.className,
            instanceId: item.instanceId,
            changeState: 'modified',
            properties: properties
        },
        requestOptions
    });
}
function buildCustomOptions(modifyOptions) {
    if (modifyOptions === null || modifyOptions === void 0 ? void 0 : modifyOptions.attributeExchange) {
        return { CustomOptions: { doAttrExchange: true } };
    }
    return undefined;
}
