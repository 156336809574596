import { getOlderVersions } from '../get';
import { itemHasOlderVersions } from '../properties';
import { assertValidItem } from '../validate';
/**
 * Deletes item from the PW datasource; will delete all older versions if item is latest version
 * @param deleteItemOptions Options for deleting item
 * @param deleteItemOptions.item Item to be deleted
 * @param deleteItemOptions.httpService Configured http service for the datasource
 * @param deleteItemOptions.requestOptions Options for the http request
 * @returns The response from the delete request
 */
export async function deleteItem({ item, httpService, requestOptions }) {
    assertValidItem(item);
    const associatedVersions = await getAssociatedVersions(item, httpService, requestOptions);
    const changeSetBody = buildChangeSetBody([...associatedVersions, item]);
    const response = await httpService.post('$changeset', changeSetBody, requestOptions);
    return response;
}
async function getAssociatedVersions(item, httpService, requestOptions) {
    if (!itemHasOlderVersions(item)) {
        return [];
    }
    const olderVersions = await getOlderVersions({
        instanceId: item.instanceId,
        httpService,
        requestOptions
    });
    return olderVersions;
}
function buildChangeSetBody(items) {
    const changeSetBody = {
        instances: items.map(deletedChangeSetInstance),
        requestOptions: {
            CustomOptions: { allowRestore: true }
        }
    };
    return JSON.stringify(changeSetBody);
}
function deletedChangeSetInstance(item) {
    return {
        schemaName: 'PW_WSG',
        changeState: 'deleted',
        className: item.className,
        instanceId: item.instanceId
    };
}
