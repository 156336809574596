/**
 * Service for making HTTP requests in order to interact with PW services
 * @see {@link HttpServiceOptions}
 */
export class HttpService {
    constructor(options) {
        this.authorization = options.authorization;
        this.baseUrl = HttpService.removeBaseUrlSlash(options.baseUrl);
        this.cache = options.cache;
        this.defaultHeaders = options.defaultHeaders;
    }
    /**
     * Sends a DELETE request to the specified baseUrl + url
     * @param url Path to send request to
     * @param body Body of the request, if any
     * @param requestOptions Additional options for the request
     * @returns Response from fetch
     */
    async delete(url, body, requestOptions) {
        return this.sendRequest('DELETE', url, body, requestOptions);
    }
    /**
     * Sends a GET request to the specified baseUrl + url
     * @param url Path to send request to
     * @param requestOptions Additional options for the request
     * @returns Response from fetch
     */
    async get(url, requestOptions) {
        return this.sendRequest('GET', url, undefined, requestOptions);
    }
    /**
     * Sends a PUT request to the specified baseUrl + url
     * @param url Path to send request to
     * @param body Body of the request, if any
     * @param requestOptions Additional options for the request
     * @returns Response from fetch
     */
    async put(url, body, requestOptions) {
        return this.sendRequest('PUT', url, body, requestOptions);
    }
    /**
     * Sends a POST request to the specified baseUrl + url
     * @param url Path to send request to
     * @param body Body of the request, if any
     * @param requestOptions Additional options for the request
     * @returns Response from fetch
     */
    async post(url, body, requestOptions) {
        return this.sendRequest('POST', url, body, requestOptions);
    }
    async sendRequest(method, url, body, requestOptions) {
        const requestUrl = this.joinBaseUrlToPath(url);
        const requestHeaders = await this.requestHeaders(url, requestOptions);
        const requestInit = this.requestInit(method, requestHeaders, body, requestOptions);
        return this.fetch(requestUrl, requestInit, requestOptions);
    }
    joinBaseUrlToPath(path) {
        if (!this.baseUrl) {
            return path;
        }
        return `${this.baseUrl}/${path}`;
    }
    async requestHeaders(url, requestOptions) {
        var _a;
        const requestHeaders = {};
        const authorizationHeader = await ((_a = this.authorization) === null || _a === void 0 ? void 0 : _a.header());
        if (authorizationHeader) {
            requestHeaders.Authorization = authorizationHeader;
        }
        requestHeaders['Content-Type'] = this.contentTypeHeader(url);
        return {
            ...requestHeaders,
            ...this.defaultHeaders,
            ...requestOptions === null || requestOptions === void 0 ? void 0 : requestOptions.headers
        };
    }
    contentTypeHeader(url) {
        if (url.includes('$file')) {
            return 'application/octet-stream';
        }
        if (url.includes('$query')) {
            return 'text/plain';
        }
        return 'application/json';
    }
    requestInit(method, headers, body, requestOptions) {
        var _a;
        const requestInit = {
            headers,
            method,
            body,
            signal: (_a = requestOptions === null || requestOptions === void 0 ? void 0 : requestOptions.abortController) === null || _a === void 0 ? void 0 : _a.signal
        };
        return requestInit;
    }
    // By not awaiting the response here or in the action methods, we can return an unresolved promise
    // This is useful for tracking progress on large file downloads
    fetch(url, requestInit, requestOptions) {
        if (this.cache && !(requestOptions === null || requestOptions === void 0 ? void 0 : requestOptions.uncached)) {
            return this.cache
                .fetch(url, requestInit, requestOptions === null || requestOptions === void 0 ? void 0 : requestOptions.cacheSuffix)
                .catch(suppressAbortError);
        }
        return fetch(url, requestInit).catch(suppressAbortError);
    }
    static removeBaseUrlSlash(url) {
        if (url === null || url === void 0 ? void 0 : url.endsWith('/')) {
            return url.slice(0, -1);
        }
        return url;
    }
}
// Prevents showing request aborted errors in console
// Since request was aborted, response is unimportant
function suppressAbortError(error) {
    if (error instanceof Error && error.name == 'AbortError') {
        return {};
    }
    throw error;
}
