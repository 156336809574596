import { createDocument } from './createDocument';
import { uploadFile } from './uploadFile';
/**
 * Creates a document and uploads a file to the datasource
 * @param createDocumentWithFileOptions Options for uploading a file
 * @param createDocumentWithFileOptions.file File to upload
 * @param createDocumentWithFileOptions.parentId instanceId of the parent folder
 * @param createDocumentWithFileOptions.documentOptions Options for creating a document with custom properties
 * @param createDocumentWithFileOptions.httpService Configured http service for the datasource
 * @param createDocumentWithFileOptions.requestOptions Options for the http request
 * @param createDocumentWithFileOptions.responseCallback Callback for tracking upload progress
 * @returns The response from the file upload; will throw original HTTP response if not ok
 */
export async function createDocumentWithFile(createDocumentWithFileOptions) {
    const documentResponse = await createDocument(createDocumentWithFileOptions);
    handleDocumentCreationError(documentResponse);
    if (documentResponse.status != 308) {
        return documentResponse;
    }
    const etag = documentResponse.headers.get('etag');
    if (!etag) {
        throw new Error('Etag not found');
    }
    const fileUploadResponse = await uploadFile(createDocumentWithFileOptions, etag);
    handleFileUploadError(fileUploadResponse);
    return fileUploadResponse;
}
/**
 * Handles errors from document creation; will throw if receives an unexpected response
 * @param response Response from document creation
 */
export function handleDocumentCreationError(response) {
    if (response.status != 200 &&
        response.status != 201 &&
        response.status != 308) {
        throw response;
    }
}
/**
 * Handles errors from file upload; will throw if receives an unexpected response
 * @param response Response from file upload
 */
export function handleFileUploadError(response) {
    if (!response.ok) {
        throw response;
    }
    if (response.status != 200 && response.status != 201) {
        throw response;
    }
}
