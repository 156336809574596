import { parseResponseInstances, query } from '../http';
import { byDefaultOrder } from '../properties';
import { assertValidInstanceId } from '../validate';
/**
 * Returns a Document - use getDataItem to include FlatSets and LogicalSets
 * @param getDataItemOptions Options for retrieving a Document
 * @param getDataItemOptions.instanceId Document instanceId
 * @param getDataItemOptions.selectProperties Properties to query; if not passed will query all properties
 * @param getDataItemOptions.selectRelationships Optional related classes to query
 * @param getDataItemOptions.httpService Configured http service for the datasource
 * @param getDataItemOptions.requestOptions Options for the http request
 * @returns A Document; will throw if not found
 */
export async function getDocument({ instanceId, selectProperties, selectRelationships, httpService, requestOptions }) {
    assertValidInstanceId(instanceId);
    const url = query({
        schemaName: 'PW_WSG',
        className: 'Document',
        instanceId: instanceId,
        select: buildDataItemSelect(selectProperties, selectRelationships)
    });
    const response = await httpService.get(url, requestOptions);
    const items = await parseResponseInstances(response);
    return items[0];
}
/**
 * Returns a DataItem
 * @param getDataItemOptions Options for retrieving a DataItem
 * @param getDataItemOptions.instanceId DataItem instanceId
 * @param getDataItemOptions.selectProperties Properties to query; if not passed will query all properties
 * @param getDataItemOptions.selectRelationships Optional related classes to query
 * @param getDataItemOptions.httpService Configured http service for the datasource
 * @param getDataItemOptions.requestOptions Options for the http request
 * @returns A DataItem; will throw if not found
 */
export async function getDataItem({ instanceId, selectProperties, selectRelationships, httpService, requestOptions }) {
    assertValidInstanceId(instanceId);
    const url = query({
        schemaName: 'PW_WSG',
        className: 'Document',
        polymorphic: true,
        instanceId: instanceId,
        select: buildDataItemSelect(selectProperties, selectRelationships)
    });
    const response = await httpService.get(url, requestOptions);
    const items = await parseResponseInstances(response);
    return items[0];
}
/**
 * Returns Documents, FlatSets, and LogicalSets for a given parent
 * @param getDataItemsOptions Options for retrieving data items
 * @param getDataItemsOptions.parentId Parent instanceId
 * @param getDataItemsOptions.selectProperties Properties to query; if not passed will query all properties
 * @param getDataItemsOptions.selectRelationships Optional related classes to query
 * @param getDataItemsOptions.httpService Configured http service for the datasource
 * @param getDataItemsOptions.requestOptions Options for the http request
 * @returns Documents, FlatSets, and LogicalSets for a given parent
 */
export async function getDataItems({ parentId, selectProperties, selectRelationships, httpService, requestOptions }) {
    assertValidInstanceId(parentId);
    const filter = buildDataItemFilter(parentId);
    const select = buildDataItemSelect(selectProperties, selectRelationships);
    const url = query({
        schemaName: 'PW_WSG',
        className: 'Document',
        polymorphic: true,
        filter: filter,
        select: select
    });
    const response = await httpService.get(url, requestOptions);
    const items = await parseResponseInstances(response);
    items.sort(byDefaultOrder);
    return items;
}
function buildDataItemFilter(parentId) {
    const filter = {
        relationshipClass: {
            relationshipClassName: 'DocumentParent',
            direction: 'forward',
            relatedClassName: 'Project'
        },
        propertyName: 'instanceId',
        operator: 'eq',
        value: parentId
    };
    return filter;
}
function buildDataItemSelect(selectProperties, selectRelationships) {
    const select = selectProperties
        ? selectProperties.map((propertyName) => ({ propertyName }))
        : [{ properties: 'all' }];
    if (!selectRelationships) {
        return select;
    }
    if (selectRelationships.auditTrail) {
        select.push(documentAuditTrailSelect);
    }
    if (selectRelationships.environment) {
        select.push(documentEnvironmentSelect);
    }
    if (selectRelationships.parent) {
        select.push(documentParentSelect);
    }
    if (selectRelationships.wreCommands) {
        select.push(documentCommandSelect);
    }
    return select;
}
export const documentAuditTrailSelect = {
    relationshipClass: {
        relationshipClassName: 'DocumentAuditTrail',
        direction: 'forward',
        relatedClassName: 'AuditTrail'
    },
    properties: 'all'
};
export const documentCommandSelect = {
    relationshipClass: {
        relationshipClassName: 'DocumentCommand',
        direction: 'forward',
        relatedClassName: 'Command',
        relatedClassPolymorphic: true
    },
    properties: 'all'
};
export const documentEnvironmentSelect = {
    relationshipClass: {
        relationshipClassName: 'DocumentEnvironment',
        direction: 'forward',
        relatedClassName: 'Environment',
        relatedClassPolymorphic: true
    },
    properties: 'all'
};
export const documentParentSelect = {
    relationshipClass: {
        relationshipClassName: 'DocumentParent',
        direction: 'forward',
        relatedClassName: 'Project'
    },
    properties: 'all'
};
