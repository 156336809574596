export function generateProjectWiseExplorerLink(datasourceInstanceId, className, instanceId) {
    const datasource = sanitizeDataSourceId(datasourceInstanceId);
    return `pw://${datasource}/Documents/${className}{${instanceId}}/`;
}
function sanitizeDataSourceId(datasourceId) {
    if (!datasourceId) {
        return '';
    }
    return datasourceId
        .replace('Bentley.PW--', '')
        .replace(/~3A/g, ':')
        .replace(/~20/g, '%20');
}
