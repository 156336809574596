export const href = {
    aha: 'https://projectwiseweb.ideas.aha.io/ideas',
    legal: 'https://connect.bentley.com/Legal',
    pwDrive: 'https://docs.bentley.com/LiveContent/web/ProjectWise%20Web%20Help-v1/en/GUID-1C2BA1ED-AAFC-4288-8DDA-8D713EF8D1AF.html',
    pwWebCommunities: 'https://communities.bentley.com/products/projectwise/content_management/w/wiki/46158/projectwise-web',
    pWWebHelp: 'https://docs.bentley.com/LiveContent/web/ProjectWise%20Web%20Help-v1/en/GUID-2FABBDAF-8B37-4C0B-81AB-3A60643F91C2.html',
    pWWebOfficeIntegrationInfo: 'https://docs.bentley.com/LiveContent/web/ProjectWise%20Web%20Help-v1/en/GUID-A5449502-1C57-4519-A2A7-E314ACFA506E.html',
    pwWebViewCommunities: 'https://communities.bentley.com/products/projectwise/content_management/w/wiki/43367/projectwise-web-view',
    pWWebViewHelp: 'https://docs.bentley.com/LiveContent/web/ProjectWise%20Web%20View%20Help-v1/en/GUID-8326CD19-3012-4F98-A0A6-A531F16B5362.html',
    pwWebViewWhatsNew: 'https://docs.bentley.com/LiveContent/web/ProjectWise%20Web%20View%20Help-v1/en/GUID-971BB13A-845C-4BF2-8894-B49FDEBF250A.html',
    pwWebWhatsNew: 'https://docs.bentley.com/LiveContent/web/ProjectWise%20Web%20Help-v1/en/GUID-7DAAE4A6-71BB-4E79-A415-370E89C132B8.html',
    pwWorkAreaHelp: 'https://docs.bentley.com/LiveContent/web/ProjectWise%20Web%20Help-v1/en/GUID-25E22029-D8CF-4EF2-9BDE-DFA4170875DF.html',
    teamsCors: 'https://communities.bentley.com/products/projectwise/content_management/w/wiki/45318/install-and-configure-web-services-gateway-cors',
    teamsHelp: 'https://communities.bentley.com/products/projectwise/content_management/w/wiki/48908/microsoft-teams-integration'
};
