/**
 * Type guard to confirm if item is {@link PWDocument}
 * @param item {@link PWItem} to check
 * @returns `true` if item is {@link PWProject}
 */
export function itemIsProject(item) {
    return itemIsType(item, 'Project');
}
/**
 * Type guard to confirm if item is {@link PWDocument}
 * @param item {@link PWItem} to check
 * @returns `true` if item is {@link PWDocument}
 */
export function itemIsDocument(item) {
    return itemIsType(item, 'Document');
}
/**
 * Type guard to confirm if item is {@link PWFlatSet}
 * @param item {@link PWItem} to check
 * @returns `true` if item is {@link PWFlatSet}
 */
export function itemIsFlatSet(item) {
    return itemIsType(item, 'FlatSet');
}
/**
 * Type guard to confirm if item is {@link PWLogicalSet}
 * @param item {@link PWItem} to check
 * @returns `true` if item is {@link PWLogicalSet}
 */
export function itemIsLogicalSet(item) {
    return itemIsType(item, 'LogicalSet');
}
/**
 * Type guard to confirm if item is {@link PWParentType}
 * @param item {@link PWItem} to check
 * @returns `true` if item is {@link PWParentType}
 */
export function itemIsParentType(item) {
    return itemIsInTypes(item, ['Project', 'FlatSet']);
}
/**
 * Type guard to confirm if item is {@link PWDataItem}
 * @param item {@link PWItem} to check
 * @returns `true` if item is {@link PWDataItem}
 */
export function itemIsDataItem(item) {
    return itemIsInTypes(item, ['Document', 'LogicalSet', 'FlatSet']);
}
/**
 * Type guard to confirm if item is {@link PWFileType}
 * @param item {@link PWItem} to check
 * @returns `true` if item is {@link PWFileType}
 */
export function itemIsFileType(item) {
    return itemIsInTypes(item, ['Document', 'LogicalSet']);
}
/**
 * Type guard to confirm if item is {@link PWSet}
 * @param item {@link PWItem} to check
 * @returns `true` if item is {@link PWSet}
 */
export function itemIsSet(item) {
    return itemIsInTypes(item, ['FlatSet', 'LogicalSet']);
}
/**
 * Filters items for {@link PWProject}s
 * @param items {@link PWItem}s to filter
 * @returns items
 */
export function filterProjects(items) {
    return items.filter(itemIsProject);
}
/**
 * Filters items for {@link PWDocument}s
 * @param items {@link PWItem}s to filter
 * @returns items
 */
export function filterDocuments(items) {
    return items.filter(itemIsDocument);
}
/**
 * Filters items for {@link PWFlatSet}s
 * @param items {@link PWItem}s to filter
 * @returns items
 */
export function filterFlatSets(items) {
    return items.filter(itemIsFlatSet);
}
/**
 * Filters items for {@link PWLogicalSet}s
 * @param items {@link PWItem}s to filter
 * @returns items
 */
export function filterLogicalSets(items) {
    return items.filter(itemIsLogicalSet);
}
/**
 * Filters items for {@link PWParentType}s
 * @param items {@link PWItem}s to filter
 * @returns items
 */
export function filterParentTypes(items) {
    return items.filter(itemIsParentType);
}
/**
 * Filters items for {@link PWDataItem}s
 * @param items {@link PWItem}s to filter
 * @returns items
 */
export function filterDataItems(items) {
    return items.filter(itemIsDataItem);
}
/**
 * Filters items for {@link PWFileType}s
 * @param items {@link PWItem}s to filter
 * @returns items
 */
export function filterFileTypes(items) {
    return items.filter(itemIsFileType);
}
/**
 * Filters items for {@link PWSet}s
 * @param items {@link PWItem}s to filter
 * @returns items
 */
export function filterSets(items) {
    return items.filter(itemIsSet);
}
function itemIsType(item, className) {
    return item.className == className;
}
function itemIsInTypes(item, classNames) {
    return classNames.some((className) => itemIsType(item, className));
}
